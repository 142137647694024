// Filename - pages/rodape.js

import React from "react";

const Rodape = () => {
	return (
		<>
		<p className="politicadeprivacidade">Canal do Elétron. 
		<br /><a href="../politicadeprivacidade">Política de privacidade</a>
		<br /><a href="../termosdeuso">Termos de uso</a>
		</p>
		</>
	);
};

export default Rodape;