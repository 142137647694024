// Filename - pages/politicadeprivacidade.js

import React from "react";
import Rodape from './rodape.js';

const PoliticaDePrivacidade = () => {
	return (
    <>
	<section id="form" className="section">
        <img src="/dominio_eletrico_logo_2023.png" alt="Logo do Domínio Elétrico" width="400" className="logo-image" />
        <h1>Política de privacidade</h1>
        <div className="privacidade-container">         
            <p>NÓS TEMOS UM COMPROMISSO COM A TRANSPARÊNCIA DO TRATAMENTO DE DADOS PESSOAIS DOS NOSSOS USUÁRIOS. POR ISSO, ESTA POLÍTICA DE PRIVACIDADE ESTABELECE COMO É FEITA A COLETA, USO E TRANSFERÊNCIA DE INFORMAÇÕES DOS USUÁRIOS QUE ACESSAM OU USAM NOSSO SITE.</p>       
            <p>O CANAL DO ELÉTRON é uma empresa que desempenha atividades na área educacional, comercializando cursos livres na modalidade educação à distância (infoprodutos).</p>    
            <p>Ao acessar esse site, você entende que coletamos e usamos suas informações pessoais nas formas descritas nesta Política, sob as normas da Lei Geral de Proteção de Dados (13.709/2018), do Direito do Consumidor, assim como demais normas previstas no ordenamento jurídico brasileiro.</p>
            <p>Você aceita esta política e concorda com tal coleta, armazenamento e uso ao se inscrever ou adquirir nossos produtos, serviços ou qualquer outro recurso, tecnologia ou funcionalidade que nós oferecemos ao acessar nosso site ou por qualquer outro meio.</p>
            <p>Fica ressalvado que podemos alterar esta política a qualquer momento, divulgando uma versão revisada e atualizada em nossos sites. A versão revisada entrará em vigor assim que disponibilizada no site, independente de prévia notificação, sendo de responsabilidade do usuário a leitura e verificação a cada vez que acessar nossos sites e serviços.</p>
            <p>Todavia, se a versão revisada incluir alteração substancial, avisaremos você com 30 dias de antecedência, divulgando o aviso sobre a alteração em nosso próprio site. Depois desse aviso de 30 dias, consideraremos que você concorda com todas as emendas feitas a essa política.</p>
            <p><strong>Importante: Política de Descadastramento (“Opt-out”)</strong></p>
            <p>O usuário cadastrado em nosso site ou adquirente de nossos produtos e que recebe nossas comunicações via e-mail pode a qualquer momento deixar de receber tais comunicações. Para tanto basta enviar um e-mail para suporte@canaldoeletron.com.br indicando o seu desejo de não mais receber comunicações ou simplesmente clicar no link de descadastro encontrado no final de cada e-mail.</p>
            <p><strong>Como coletamos informações a seu respeito?</strong></p>
            <p>Quando os visitantes deixam comentários no site, coletamos os dados mostrados no formulário de comentários, além do endereço de IP e de dados do navegador do visitante, e as páginas que você acessou em nosso site para auxiliar na detecção de spam.</p>
            <p>Quando você se inscreve para receber atualizações do nosso site, para fazer o download de algum dos nossos conteúdos, para participar de eventos online que promovemos, para comprar um dos nossos produtos, ou qualquer outra atividade em que solicitamos um cadastro seu, poderemos coletar os seguintes tipos de informações:</p>
            <ul>
            <li>Informações de conta: o seu nome, endereço, telefone, e-mail, dados qualificativos, tais como, CPF, RG, profissão, instituição de ensino (escola técnica ou faculdade) e outras informações semelhantes.</li>
            <li>Informações financeiras: os números da conta bancária e do cartão de crédito que você forneceu a alguma instituição de pagamento parceira quando adquiriu um produto ou um serviço ofertado pelo CANAL DO ELÉTRON.</li>
            <li>Poderemos solicitar que você forneça informações adicionais que poderemos usar para verificar sua identidade ou endereço ou gerenciar risco, como sua data de nascimento, número de registro nacional, nacionalidade e outras informações de identificação. Também podemos obter informações suas através de terceiros, como centros de crédito e serviços de verificação de identidade.</li>
            <li>Quando você usar as ferramentas oferecidas nos produtos comercializados pelo CANAL DO ELÉTRON, coletamos informações sobre suas transações e outras atividades suas em nosso site.</li>
            <li>Se você envia imagens para o site, evite enviar as que contenham dados de localização incorporados (EXIF GPS). Visitantes podem baixar estas imagens do site e extrair delas seus dados de localização.</li>
            <li>Podemos coletar informações sobre seu computador ou outro dispositivo de acesso com finalidade de prevenção contra fraude e, principalmente, violação de direitos de autor que importem em pirataria. Por fim, podemos coletar informações adicionais sobre você por outros meios, como de contatos com nossa equipe de suporte ao cliente, de resultados de suas respostas em pesquisas, de interações com membros da equipe administrativa dos produtos e/ou das empresas pertençam ao mesmo grupo econômico do CANAL DO ELÉTRON.</li>
            </ul>
            <p><strong>Como usamos cookies?</strong></p>
            <p>Quando você acessa o nosso site, nós, incluindo as empresas que contratamos para acompanhar como nosso site é usado, podemos colocar pequenos arquivos de dados chamados “cookies” no seu computador. Enviamos um “cookie da sessão” para o seu computador quando você acessa nosso site, entra em sua conta ou acessa os produtos e/ou serviços comercializados pelo CANAL DO ELÉTRON. Esse tipo de cookie nos ajuda a reconhecê-lo caso você visite várias páginas em nosso site durante a mesma sessão, para que não precisemos solicitar suas informações e/ou senha em todas as páginas. Depois que você sair ou fechar o seu navegador, esse cookie irá expirar e deixará de ter efeito. Também usamos cookies de maior duração para outras finalidades, como, por exemplo, para exibir o seu endereço de e-mail em um formulário de cadastro ou acesso, para que você não precise digitar novamente o endereço de e-mail sempre que quiser fazer um cadastro ou acesso em algum dos nossos serviços ou produtos. Codificamos nossos cookies para que apenas nós possamos interpretar as informações armazenadas neles. Você está livre para recusar nossos cookies caso o seu navegador permita, mas isso pode interferir no uso do nosso site. Nós e nossos prestadores de serviço também usam cookies para personalizar nossos serviços e/ou produtos, conteúdo e publicidade, avaliar a eficiência das promoções e promover confiança e segurança. Você pode encontrar cookies de terceiros ao acessar os serviços ou ao adquirir os produtos do CANAL DO ELÉTRON em determinados sites que não estão sob nosso controle.</p>
            <p><strong>Remarketing do Google</strong></p>
            <p>Nós utilizamos o recurso de Remarketing do Google Ads para veicular anúncios em sites de parceiros do Google. Este recurso permite identificar que você visitou o nosso site e assim o Google pode exibir o nosso anúncio para você em diferentes websites. Diversos fornecedores de terceiros, inclusive o Google, compram espaços de publicidade em sites da Internet. Nós, eventualmente, contratamos o Google para exibir nossos anúncios nesses espaços. Para identificar a sua visita no nosso site, tanto outros fornecedores de terceiros, quanto o Google, utilizam-se de cookies, de forma similar ao exposto na seção “Como usamos cookies?”. Você pode desativar o uso de cookies pelo Google acessando o Gerenciador de preferências de anúncio.</p>
            <p><strong>Remarketing do Facebook Ads</strong></p>
            <p>Nós utilizamos o recurso de Remarketing do Facebook Ads para veicular anúncios dentro das plataformas da Meta. Este recurso permite identificar que você visitou o nosso site e assim a Meta pode exibir os nossos anúncios para você dentro de suas plataformas de mídia. Para identificar a sua visita no nosso site utilizamos os cookies, de forma similar ao exposto na seção “Como usamos cookies?”. Caso você venha a visualizar um dos nossos anúncios nas plataformas da Meta e não queira mais visualizá-lo, no próprio anúncio existem opções para ocultar e não visualizar futuramente os nossos anúncios.</p>
            <p><strong>Como protegemos e armazenamos informações pessoais</strong></p>
            <p>Ao longo desta política, usamos o termo “informações pessoais” para descrever informações que possam ser associadas a uma determinada pessoa e possam ser usadas para identificar essa pessoa. Nós não consideramos como informações pessoais as informações que devem permanecer anônimas, para que elas não identifiquem um determinado usuário. Armazenamos e processamos suas informações pessoais em nossos computadores e as protegemos sob medidas de proteção físicas, eletrônicas e processuais. Usamos proteções de computador, como firewalls e criptografia de dados, aplicamos controles de acesso físico a nossos prédios e arquivos e autorizamos o acesso a informações pessoais apenas para os funcionários que precisem delas para cumprir suas responsabilidades profissionais, que o fazem de forma ética.</p>
            <p><strong>Como usamos as informações pessoais que coletamos</strong></p>
            <p>Nossa finalidade principal ao coletar informações pessoais é fornecer a você uma experiência segura, tranquila, eficiente e personalizada. Para isso, usamos suas informações pessoais para:</p>
            <ul>
            <li>Fornecer os serviços, comercializar os produtos e prestar o suporte ao usuário;</li>
            <li>Processar transações e enviar avisos sobre as suas transações;</li>
            <li>Solucionar disputas, cobrar taxas e solucionar problemas;</li>
            <li>Impedir atividades potencialmente proibidas ou ilegais;</li>
            <li>Personalizar, avaliar e melhorar nossos serviços e produtos, além do conteúdo e do layout do nosso site;</li>
            <li>Enviar materiais de marketing direcionados, avisos de atualização no serviço e/ou do produto e ofertas promocionais com base nas suas preferências de comunicação;</li>
            <li>Comparar informações, para uma maior precisão e verificá-las com terceiros.</li>
            </ul>
            <p><strong>Marketing</strong></p>
            <p>Não vendemos nem alugamos suas informações pessoais para terceiros para fins de marketing sem seu consentimento explícito. Podemos combinar suas informações com as informações que coletamos de outras empresas e usá-las para melhorar e personalizar nossos serviços, conteúdo e publicidade. Se não desejar receber nossas mensagens de marketing nem participar de nossos programas de personalização de anúncios, basta indicar sua preferência mandando-nos um e-mail para suporte@canaldoeletron.com.br ou simplesmente clicando no link de descadastramento fornecido no final de todos os nossos e-mails.</p>
            <p><strong>Como compartilhamos informações pessoais com outras partes</strong></p>
            <p>Podemos compartilhar suas informações pessoais com:</p>
            <ul>
            <li>Membros da equipe administrativa das empresas pertencem ao mesmo grupo econômico do CANAL DO ELÉTRON para fornecer conteúdo, produtos e serviços conjuntos (como registro, transações e suporte ao cliente) e para ajudar a detectar e impedir atos potencialmente ilegais e violações de nossas políticas, além de cooperar nas decisões quanto a seus produtos, serviços e comunicações. Os membros da nossa equipe usarão essas informações para enviar comunicações de marketing somente se você tiver voluntariamente se cadastrado em nossas listas de e-mail.</li>
            <li>Prestadores dos serviços sob contrato que colaboram, em partes, de nossas operações comerciais (prevenção contra fraude e pirataria, atividades de cobrança, marketing, serviços de tecnologia). Esses fornecedores de serviço só usam suas informações em relação aos serviços que realizam para nós, e não em benefício próprio.</li>
            <li>Empresas com as quais pretendemos nos fundir ou adquirir. (Se ocorrer uma fusão, exigiremos que a nova entidade constituída siga essa política de privacidade com relação às suas informações pessoais. Se suas informações pessoais puderem ser usadas contra essa política, você receberá um aviso prévio).</li>
            <li>Autoridades policiais, oficiais do governo ou outros terceiros quando: (i) formos obrigados a isso por intimação, decisão judicial ou grave ameaça de qualquer tipo; (ii) precisarmos fazer isso para estar em conformidade com a lei ou com as regras de associação de cartão de crédito; (iii) estivermos cooperando com uma investigação policial em andamento; acreditamos, de boa-fé, que a divulgação das informações pessoais é necessária para impedir danos físicos ou perdas financeiras ou reportar atividade ilegal suspeita.</li>
            <li>Outros terceiros apenas com seu consentimento para tanto. Note que esses terceiros podem estar em outros países nos quais a legislação sobre o processamento de informações pessoais seja menos rígida do que a do seu país. Nos casos em que nossa empresa e um parceiro promovem juntos nossos serviços ou comercializam nossos produtos em parceria, nós podemos divulgar ao parceiro algumas informações pessoais tais como nome, endereço e nome de usuário das pessoas que se inscreveram nos nossos serviços ou adquiriram nossos produtos com resultado da promoção conjunta com o único propósito de permitir a nós e ao parceiro avaliar os resultados da promoção. Nesse caso, informações pessoais não podem ser usadas pelo parceiro para nenhum outro propósito. Não vendemos ou alugamos nenhuma das suas informações pessoais para terceiros no curso normal dos negócios e só compartilha suas informações pessoais com terceiros, conforme descrito nesta política.&nbsp;</li>
            </ul>
            <p>ATENÇÃO! SE VOCÊ SE INSCREVER NA NOSSA BASE DE DADOS EM UM SITE DE TERCEIROS, OU POR MEIO DE UM APLICATIVO DE TERCEIROS, QUALQUER INFORMAÇÃO INSERIDA NAQUELE SITE OU APLICATIVO (E NÃO DIRETAMENTE NO NOSSO SITE) SERÁ COMPARTILHADA COM O PROPRIETÁRIO DESSE SITE OU APLICATIVO, E SUAS INFORMAÇÕES PODEM ESTAR SUJEITAS AS POLÍTICAS DE PRIVACIDADE DELES.</p>
            <p>Para entrar em contato com o site, basta escrever para suporte@canaldoeletron.com.br</p>
        </div>
    </section>
    <Rodape />
    </>
	);
};

export default PoliticaDePrivacidade;